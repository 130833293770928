import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    playlists: null
  },
  mutations: {
    setPlaylists(state, _playlists) {
      state.playlists = _playlists;
    }
  },
  actions: {}
});
