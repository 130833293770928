<template>
  <div id="app">
    <NavBar />
    <Player ref="player" />
    <ItemList v-on:select-video="$refs.player.playVideo($event)" />
  </div>
</template>

<script>
import NavBar from "@components/NavBar.vue";
import Player from "@components/Player.vue";
import ItemList from "@components/ItemList.vue";
import axios from "axios";

export default {
  name: "app",
  components: {
    NavBar,
    Player,
    ItemList
  },
  mounted() {
    this.loadPlaylist();
  },
  updated: function() {},
  destroyed: function() {},
  computed: {},
  methods: {
    loadPlaylist() {
      let that = this;

      axios
        .get("https://api.ensplash.com/api/v1/videos.json", {
          responseType: "json"
        })
        .then(function(response) {
          // handle success
          that.$store.commit("setPlaylists", response.data);
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        })
        .then(function() {
          // always executed
        });
    }
  }
};
</script>

<style lang="scss">
[v-cloak] {
  display: none;
}
a,
a:hover,
a:visited,
a:active,
a:focus {
  opacity: 0.9;
}
a:hover,
a:focus {
  opacity: 1;
}
a:visited {
  opacity: 0.6;
}
body {
  margin: 0;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: black;
  margin: 0;

  display: flex;
  flex-direction: column;
  height: 100vh;
}
</style>
