<template>
  <div id="player"></div>
</template>

<script>
import Vue from "vue";
import Clappr from "clappr";
import PlaybackRatePlugin from "clappr-playback-rate-plugin";

export default Vue.extend({
  name: "Player",
  props: {
    msg: String
  },
  data() {
    return {
      player: null
    };
  },
  mounted() {
    this.initPlayer();
  },
  updated: function() {},
  destroyed: function() {},
  computed: {},
  methods: {
    initPlayer() {
      this.player = new Clappr.Player({
        source: "",
        parentId: "#player",
        height: "100%",
        width: "100%",
        hlsUseNextLevel: true,
        playInline: true,
        recycleVideo: Clappr.Browser.isMobile,
        playbackNotSupportedMessage: "🐱",
        plugins: {
          core: [Clappr.MediaControl, PlaybackRatePlugin]
        },
        playbackRateConfig: {
          defaultValue: "1.0",
          options: [
            { value: "0.70", label: "0.7x" },
            { value: "0.85", label: "0.85x" },
            { value: "1.00", label: "1x" },
            { value: "1.25", label: "1.25x" },
            { value: "1.50", label: "1.5x" }
          ]
        },
        gaAccount: "UA-1658815-3",
        hideMediaControl: true,
        hideVolumeBar: true
      });
    },
    playVideo(cfg) {
      this.player.configure({
        poster: cfg.thumbnail,
        gaTrackerName: cfg.title
      });
      this.player.load(cfg.playback_url, "application/vnd.apple.mpegurl", true);
    }
  }
});
</script>

<style>
#player {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
}

[data-player] {
  flex-grow: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
[data-no-op] p[data-no-op-msg] {
  opacity: 0.6;
}
[data-no-op] canvas[data-no-op-canvas] {
  opacity: 0.1;
}
.container[data-container] {
  background-color: #111;
}
</style>
