var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.playlists)?_c('div',{attrs:{"id":"item-list"}},[_vm._l((Object.entries(_vm.$store.state.playlists)
      .sort()
      .reverse()),function(ref){
      var year = ref[0];
      var ydata = ref[1];
return [_vm._l((Object.entries(ydata)
        .sort()
        .reverse()),function(ref){
        var month = ref[0];
        var mdata = ref[1];
return _vm._l((Object.entries(mdata)
          .sort()
          .reverse()),function(ref){
          var day = ref[0];
          var ddata = ref[1];
return _c('div',{key:year + '-' + month + '-' + day,staticClass:"item"},[_c('span',{staticClass:"date"},[_vm._v(_vm._s(year)+"-"+_vm._s(month)+"-"+_vm._s(day))]),_c('a',{on:{"click":function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.$emit('select-video', ddata)}}},[_vm._v(_vm._s(ddata.title))])])})})]})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }