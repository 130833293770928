<template>
  <div v-if="$store.state.playlists" id="item-list">
    <template
      v-for="[year, ydata] in Object.entries($store.state.playlists)
        .sort()
        .reverse()"
    >
      <template
        v-for="[month, mdata] in Object.entries(ydata)
          .sort()
          .reverse()"
      >
        <div
          class="item"
          v-for="[day, ddata] in Object.entries(mdata)
            .sort()
            .reverse()"
          v-bind:key="year + '-' + month + '-' + day"
        >
          <span class="date">{{ year }}-{{ month }}-{{ day }}</span>
          <a @click.exact="$emit('select-video', ddata)">{{ ddata.title }}</a>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "ItemList",
  data() {
    return {};
  },
  mounted() {},
  updated() {},
  destroyed() {},
  methods: {}
});
</script>

<style lang="scss" scoped>
#item-list {
  background-color: black;
  max-height: 20rem;
  max-height: 50vh;
  overflow-y: auto;
  margin: 10px;

  .item {
    color: wheat;
    opacity: 0.8;
    padding: 5px;

    a {
      font-family: Arial, Helvetica, sans-serif;
      cursor: pointer;
    }
    .date {
      font-family: "Courier New", Courier, monospace;
      font-size: small;
      color: gainsboro;
      margin-right: 1rem;
    }
  }
}
</style>
