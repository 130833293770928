<template>
  <div class="navbar">
    <Logo />
    <a href="https://www.douban.com/group/647000/">
      <img src="https://www.douban.com/pics/icon/dou24.png" />
    </a>
  </div>
</template>

<script>
import Logo from "@components/Logo.vue";

export default {
  name: "NavBar",
  components: {
    Logo
  }
};
</script>

<style lang="scss" scoped>
.navbar {
  margin: 0;
  padding: 5px;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 600px) {
    margin: 0;
    text-align: center;
  }

  img {
    width: 24px;
    height: 24px;
    border-radius: 2px;
  }
}
</style>
